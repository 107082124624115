import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Container } from './styles';

import instalacoes from '~/assets/equipamentos/instalacoes.webp';
import clinica from '~/assets/equipamentos/clinica.webp';
import consultorio from '~/assets/equipamentos/consultorio.webp';
import salas from '~/assets/equipamentos/salas.webp';

function Equipamentos() {
  return (
    <>
      <Container className="mb-4">
        <div className="background">
          <div className="container">
            <div className="row py-lg-4">
              <div className="col-lg-12 text-center py-5 py-lg-0">
                <h3 className="text-white font-weight-bold display-lg-4 mb-0">
                  EQUIPAMENTOS DE <br />
                  <span className="borda-palavras display-lg-4">
                    ÚLTIMA GERAÇÃO
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-none d-lg-block">
          <div className="row mt-5">
            <div className="col-lg-3 text-center">
              <div className="">
                <img src={instalacoes} alt="Fachada Physic" />
                <h4 className="h6 mt-3">Instalações modernas</h4>
              </div>
            </div>
            <div className="col-lg-3 text-center">
              <div className="">
                <img src={clinica} alt="Clinia Equipada" />
                <h4 className="h6 mt-3">Clínica equipada</h4>
              </div>
            </div>
            <div className="col-lg-3 text-center">
              <div className="">
                <img src={consultorio} alt="Consultórios médicos" />
                <h4 className="h6 mt-3">Consultórios médicos</h4>
              </div>
            </div>
            <div className="col-lg-3 text-center">
              <div className="">
                <img src={salas} alt="Salas de fisioterapias" />
                <h4 className="h6 mt-3">Salas de fisioterapias</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-4 d-lg-none d-block">
          <Carousel showArrows={false}>
            <div className="text-center">
              <div className="">
                <img src={instalacoes} alt="Fachada Physic" />
                <h4 className="h5 mt-3">Instalações modernas</h4>
              </div>
            </div>
            <div className="text-center">
              <div className="">
                <img src={clinica} alt="Clinia Equipada" />
                <h4 className="h5 mt-3">Clínica equipada</h4>
              </div>
            </div>
            <div className="text-center">
              <div className="">
                <img src={consultorio} alt="Consultórios médicos" />
                <h4 className="h5 mt-3">Consultórios médicos</h4>
              </div>
            </div>
            <div className="text-center">
              <div className="">
                <img src={salas} alt="Salas de fisioterapias" />
                <h4 className="h5 mt-3">Salas de fisioterapias</h4>
              </div>
            </div>
          </Carousel>
        </div>
      </Container>
    </>
  );
}

export default Equipamentos;
