import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, BgGrayInvert } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import osteopatia from '~/assets/metodos/osteopatia.png';
import procedimento from '~/assets/metodos/osteopatia-bgray.png';

function Osteopatia() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Osteopatia</title>
          <meta
            name="description"
            content="Clínica Physic utiliza a osteopatia como técnica alternativa para aliviar dores, recuperar o equilíbrio e estimular a auto cura."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  OSTEOPATIA
                </h1>
                <div className="text-center mt-5 mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 justify-content-center align-items-center">
            <div className="col-lg-6">
              <div>
                <h2 className="h4 h3-md font-weight-bold mb-3 mb-lg-4 text-center text-lg-left">
                  TÉCNICAS MANUAIS PARA <br className="d-none d-lg-block" />
                  RECUPERAÇÃO, EQUILÍBRIO <br className="d-none d-lg-block" />E
                  AUTO CURA
                </h2>
              </div>
              <div className="col d-block d-lg-none px-0 mb-4">
                <img src={osteopatia} alt="Osteopatia" />
              </div>
              <div>
                <p className="h5 h6-md mb-3">
                  Entendemos que o nosso corpo todo está interligado e que ele
                  possui a possibilidade de se reequilibrar e auto curar-se
                  naturalmente. Ao profissional Osteopata cabe a missão de
                  identificar e normalizar as estruturas dolorosas para que este
                  reequilíbrio ocorra.
                </p>
                <p className="h5 h6-md mb-3">
                  Assim, trata-se de um tratamento alternativo, que foca na
                  origem da dor e não apenas no alívio dos sintomas, utilizando
                  técnicas manuais como um instrumento para o alívio da dor e
                  promoção de saúde, sem o auxílio de remédios ou outras
                  ferramentas da medicina convencional.
                </p>
                <p className="h5 h6-md mb-3">
                  Para o diagnóstico correto, nossos pacientes passam por uma
                  avaliação global detalhada e testes específicos para
                  identificar a real causa do problema e proceder ao protocolo
                  terapêutico.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <img src={osteopatia} alt="Osteopatia" />
            </div>
          </div>
        </div>
      </Container>
      <BgGrayInvert>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-5">
              <h2 className="h4 h1-md font-weight-bold mb-4">
                PARA QUE SERVE:
              </h2>
              <ul className="pl-lg-5">
                <li>
                  <h3 className="h6 h5-md">
                    • Aplicado em tratamentos diversos, desde os pediátricos aos
                    jovens adultos e a terceira idade;
                  </h3>
                </li>
                <li>
                  <h3 className="h6 h5-md">
                    • Equilibra as funções do organismo;
                  </h3>
                </li>
                <li>
                  <h3 className="h6 h5-md">
                    • Atua em vários sistemas do corpo, como o Sistema
                    Musculoesquelético, responsável pelo nosso aparelho
                    locomotor (músculos, ossos e articulações).
                  </h3>
                </li>
              </ul>
              <h2 className="h4 h2-md font-weight-bold mb-4">Resultados:</h2>
              <p className="h5 h6-md">
                A durabilidade do tratamento, incluindo a quantidade de sessões
                e o período de intervalo entre cada uma, depende da resposta
                sintomática individualizada de cada paciente.
              </p>
            </div>
          </div>
        </div>
        <img
          src={procedimento}
          alt="Osteopatia"
          className="d-block d-lg-none"
        />
      </BgGrayInvert>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Osteopatia;
