import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-pilates.png';
import mobile from '~/assets/banners-paginas/banner-pilates-mobile.png';

export const Container = styled.div`
  .borda {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #b7b7b7;
    font-weight: bold;
    font-size: 6rem;
  }

  p,
  ul li {
    color: #333;
  }

  p {
    span {
      color: #841619;
    }
  }

  ul li {
    line-height: 2rem;
  }

  h2,
  h3 {
    color: #841619;
  }
  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 180px;
  }

  .lh-4 {
    line-height: 4rem;
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 250px;
    }

    .borda {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 575px) {
    .borda {
      font-size: 14vw;
    }

    .mg-top {
      margin-top: 300px;
    }
  }
`;
