import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-baropodometria.png';
import mobile from '~/assets/banners-paginas/banner-baropodometria-mobile.png';
import pe from '~/assets/metodos/barometria.png';

export const Container = styled.div`
  p,
  ul li {
    color: #333;
  }

  p {
    span {
      color: #841619;
    }
  }

  ul li {
    line-height: 2rem;
  }

  h2,
  h3 {
    color: #841619;
  }
  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 180px;
  }

  .lh-4 {
    line-height: 4rem;
  }

  .rec-dot_active {
    background-color: #707070 !important;
    box-shadow: none !important;
    opacity: 0.75 !important;
  }

  .rec-dot {
    background-color: #707070;
    box-shadow: none;
    opacity: 0.25;
    height: 13px;
    width: 13px;
  }

  @media screen and (min-width: 1400px) {
    .mg-top {
      margin-top: 200px;
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 230px;
    }
  }

  @media screen and (max-width: 575px) {
    .mg-top {
      margin-top: 300px;
    }
  }
`;

export const BgGray = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  h4,
  p,
  ul li {
    color: #333;
  }

  .red,
  h2,
  h3 {
    color: #841619;
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(100% 100%, 20% 100%, 0% 0%, 100% 0%);
    background: #fff;
    div {
      background-image: url(${pe});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(100% 100%, 22% 100%, 2% 0%, 100% 0%);
    }
  }
`;
