import React from 'react';
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './services/history';

import ScrollToTop from '~/components/ScrollToTop';
import Header from '~/components/Header';
import Footer from '~/components/Footer';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyle from './styles/global';

function App() {
  return (
    <>
      <Router basename="/httpdocs" history={history}>
        <ScrollToTop>
          <GlobalStyle />
          <Header />
          <Routes />
          <Footer />
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
