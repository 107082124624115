import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import pilates from '~/assets/metodos/exercicio.png';
import respiracao from '~/assets/icones/respiracao.svg';
import fluidez from '~/assets/icones/fluidez.svg';
import controle from '~/assets/icones/controle.svg';
import concentracao from '~/assets/icones/concentracao.svg';
import forca from '~/assets/icones/forca.svg';
import precisao from '~/assets/icones/precisao.svg';

function Pilates() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Pilates</title>
          <meta
            name="description"
            content="Clínica Physic trabalha Pilates com fisioterapia para proporcionar mais força, resistência e flexibilidade para seus pacientes."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  PILATES
                </h1>
                <h2 className="h5 h4-md my-5 my-lg-0 text-white font-italic text-center font-weight-bold">
                  Força, resistência e flexibilidade
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 text-center my-4">
              <h2 className="h4 h2-md font-weight-bold mb-4">
                TÉCNICA PARA FORTALECER MÚSCULOS E PREVENIR PROBLEMAS NA COLUNA
              </h2>
              <p className="h4">
                <span>
                  O Método Pilates com fisioterapia promove a saúde e viabiliza
                  a <br className="d-none d-lg-block" />
                  reabilitação corporal utilizando 6 princípios:
                </span>{' '}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-lg-2 text-center">
              <div className="num">
                <span className="borda">1</span>
                <img src={respiracao} className="w-65" alt="Respiração" />
              </div>
              <h3 className="h6 h5-md font-weight-bold">Respiração</h3>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <div className="num pt-5">
                <span className="borda">2</span>
                <img src={fluidez} className="w-65" alt="Fluidez" />
              </div>
              <h3 className="h6 h5-md font-weight-bold">Fluidez</h3>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <div className="num">
                <span className="borda">3</span>
                <img src={controle} className="w-45" alt="Controle" />
              </div>
              <h3 className="h6 h5-md font-weight-bold">Controle</h3>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <div className="num pt-5">
                <span className="borda">4</span>
                <img src={concentracao} className="w-65" alt="Concentração" />
              </div>
              <h3 className="h6 h5-md mt-2 pt-1 mb-lg-0 pt-lg-0 font-weight-bold">
                Concentração
              </h3>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <div className="num">
                <span className="borda">5</span>
                <img src={forca} className="w-45" alt="Centro de força" />
              </div>
              <h3 className="h6 h5-md font-weight-bold">
                Centro <br />
                de força
              </h3>
            </div>
            <div className="col-4 col-lg-2 text-center">
              <div className="num pt-5">
                <span className="borda">6</span>
                <img src={precisao} className="w-65" alt="Precisão" />
              </div>
              <h3 className="h6 h5-md font-weight-bold">Precisão</h3>
            </div>
          </div>
          <div className="row pt-5 pt-lg-0">
            <div className="col-lg-7 order-1 order-lg-0 py-4 py-lg-0">
              <img src={pilates} alt="Mulher fazendo pilates" />
            </div>
            <div className="col-lg-4 d-flex align-items-center order-0 order-lg-1">
              <div>
                <p className="h5 h6-md mb-4">
                  Na prática, trata-se de um conjunto de exercícios de baixo
                  impacto que podem ser realizados tanto no solo, quanto em
                  equipamentos com sistemas de molas que ajudam e/ou dificultam
                  os movimentos.
                </p>
                <p className="h5 h6-md">
                  Isso permite que cada sessão seja uma experiência única, pois
                  a técnica oferece mais de 500 exercícios em diversas
                  intensidades, desde o nível básico ao avançado, adaptando-se
                  às características individuais da pessoa, como: tipo físico,
                  idade, condicionamento e outras.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Pilates;
