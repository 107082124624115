import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-atletas.png';
import mobile from '~/assets/banners-paginas/banner-atletas-mobile.png';
import massagem from '~/assets/metodos/atletas-massagem.png';
import fisioterapia from '~/assets/metodos/atletas-fisioterapia.png';

export const Container = styled.div`
  p {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  .red {
    color: #841619;
    font-weight: bold;
  }

  h2 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      left: 45px;
      top: 4px;
    }
  }

  .runner {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      left: 21px;
      top: 4px;
    }
  }

  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 150px;
  }

  .titulo {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  @media screen and (min-width: 1400px) {
    .mg-top {
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      span::after {
        left: -3px;
        top: 3px;
      }
    }
    .mg-borda {
      margin-right: 195px;
      margin-top: 30px;
    }

    .mg-runner {
      margin-right: 159px;
      margin-top: 30px;
    }

    .runner {
      span::after {
        left: -3px;
        top: 3px;
      }
    }

    .absolute-mobile {
      position: absolute;
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 250px;
    }
  }
`;

export const Beneficios = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  ul li {
    color: #841619;
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    color: #841619;
  }

  p {
    color: #333;
  }

  h2 {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(88% 100%, 0% 100%, 0% 0%, 98% 0%);
    background: #fff;
    div {
      background-image: url(${massagem});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(86% 100%, 0% 100%, 0% 0%, 96% 0%);
    }
  }
`;

export const BgGray = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  h3 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      bottom: 6px;
      left: 357px;
    }
  }

  .titulo {
    color: #841619;
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  h3,
  ul li,
  p {
    color: #333;
  }

  h2 {
    color: #841619;
  }
`;

export const Fisioterapia = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  ul li {
    color: #841619;
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    color: #841619;
  }

  p {
    color: #333;
  }

  h2 {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(88% 100%, 0% 100%, 0% 0%, 98% 0%);
    background: #fff;
    div {
      background-image: url(${fisioterapia});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(86% 100%, 0% 100%, 0% 0%, 96% 0%);
    }
  }
`;
