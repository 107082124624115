import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-biomecanica.png';
import mobile from '~/assets/banners-paginas/banner-biomecanica-mobile.png';
import bio from '~/assets/metodos/bio.png';

export const Container = styled.div`
  p,
  ul li {
    color: #333;
  }

  p {
    span {
      color: #841619;
    }
  }

  ul li {
    line-height: 2rem;
  }

  h2,
  h3 {
    color: #841619;
  }
  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 200px;
  }

  @media screen and (min-width: 991px) {
    .lh-4 {
      line-height: 4rem;
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
    }

    .mg-top {
      margin-top: 260px;
    }
  }

  @media screen and (max-width: 575px) {
    .mg-top {
      margin-top: 300px;
    }
  }
`;

export const BgGray = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  h2 {
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      bottom: 71px;
      left: 11px;
    }

    .baixo::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-baixo);
      z-index: -1;
      position: absolute;
      bottom: 4px;
      left: 13px;
    }
  }

  h4,
  p,
  ul li {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(100% 100%, 20% 100%, 0% 0%, 100% 0%);
    background: #fff;
    div {
      background-image: url(${bio});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(100% 100%, 22% 100%, 2% 0%, 100% 0%);
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      span::after {
        bottom: 45px;
      }
    }
  }
`;
