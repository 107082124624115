import React from 'react';
import PoliticaPrivacidadeComponent from '~/components/PoliticaPrivacidade';
import Contato from '~/components/Contato';

export default function PoliticaPrivacidade() {
  return (
    <>
      <PoliticaPrivacidadeComponent
        nome="Clínica Physic"
        email="contato@clinicaphysic.com.br"
        site="https://www.clinicaphysic.com.br/"
        cnpj="28.649.206/0001-66"
        endereco="Av. República do Líbano, 592 - Ibirapuera, São Paulo"
        razaoSocial="Clinica Physic - Medicina, Fisioterapia e Treinamento Ltda"
        plataforma="Google"
      />
      <Contato />
    </>
  );
}
