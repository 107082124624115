import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'react-bootstrap';
import api from '~/services/api';

import { Container } from './styles';

export default function Categorias({ className, onSelect }) {
  const [categorias, setCategorias] = useState([]);
  const [width, setWidth] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  // useEffect(() => {
  //   api.get('blog/categories').then(response => {
  //     setCategorias(response.data);
  //   });
  // }, []);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  // useLayoutEffect(() => {
  //   window.addEventListener('resize', updateSize);
  //   updateSize();
  //   return () => window.removeEventListener('resize', updateSize);
  // }, [updateSize]);

  const handleClick = useCallback(
    categoria_id => {
      onSelect(categoria_id);
      setShowDropdown(false);
    },
    [onSelect]
  );
  return (
    <Container className={className}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h5 className="font-italic" id="categoria">
              CATEGORIAS
            </h5>
            <div className="d-flex">
              <button
                type="button"
                onClick={() => onSelect(5)}
                className="small-lg normal-xxl btn btn-categorias py-1 px-3 mr-2"
              >
                Todos
              </button>

              {width >= 992 ? (
                <>
                  {categorias.map(categoria => (
                    <button
                      key={categoria.id}
                      type="button"
                      // onClick={() => onSelect(categoria.id)}
                      onClick={() => onSelect(5)}
                      className="small-lg normal-xxl btn btn-categorias py-1 px-3 mr-2"
                    >
                      {categoria.name}
                    </button>
                  ))}
                </>
              ) : (
                <Dropdown
                  show={showDropdown}
                  onToggle={() => setShowDropdown(!showDropdown)}
                >
                  {/* <Dropdown.Toggle id="dropdown-basic" className="btn-mais">
                    Mais
                  </Dropdown.Toggle> */}

                   
                    {categorias.map(categoria => (
                      <button
                        key={categoria.id}
                        type="button"
                        onClick={() => handleClick(categoria.id)}
                        className="small-lg normal-xxl btn btn-categorias btn-categorias-mob py-1 px-3 mr-2 d-block "
                      >
                        {categoria.name}
                      </button>
                    ))}
                   
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

Categorias.propTypes = {
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

Categorias.defaultProps = {
  className: '',
  onSelect: () => {},
};
