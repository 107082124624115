import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import pulando from '~/assets/metodos/pulando.png';
import agachamento from '~/assets/metodos/agachamento.png';

function Treinamento() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Physic – Treinamento Funcional</title>
          <meta
            name="description"
            content="Clínica Physic oferece treinamento funcional personalizado para aumentar a força, o equilíbrio e o condicionamento físico."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  TREINAMENTO FUNCIONAL
                </h1>
                <h2 className="my-5 my-lg-0 text-white font-italic text-center h5 h4-md font-weight-bold">
                  Physic Fit
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 d-none d-lg-block">
              <img src={pulando} alt="Mulher Pulando" />
            </div>
            <div className="col-lg-6">
              <h2 className="h4 h3-md font-weight-bold text-lg-center mb-4">
                PROGRAMA DE TREINAMENTO FUNCIONAL PERSONALIZADO
              </h2>
              <p className="h5 h6-md">
                O Physic Fit é um programa de treinamento funcional que visa
                proporcionar exercícios na intensidade e frequência correta para
                um melhor desempenho, resultando na manutenção da saúde do
                corpo, ajudando a desenvolver sua funcionalidade no dia a dia
                através de movimentos naturais, como empurrar, puxar, levantar e
                agachar.
              </p>
              <img
                src={pulando}
                alt="Mulher Pulando"
                className="d-lg-none d-block my-4"
              />
              <p className="h5 mb-3 color">
                A realização de atividades físicas supervisionadas e com
                programação adequada contribui para:{' '}
              </p>
              <ul className="ml-lg-5">
                <li>
                  <h3 className="h5">• Aumentar a força;</h3>
                </li>
                <li>
                  <h3 className="h5">• Potência e flexibilidade muscular;</h3>
                </li>
                <li>
                  <h3 className="h5">• Equilíbrio e a coordenação motora;</h3>
                </li>
                <li>
                  <h3 className="h5">
                    • Agilidade e precisão nas tarefas diárias;
                  </h3>
                </li>
                <li>
                  <h3 className="h5">• Resistência cardiorrespiratória;</h3>
                </li>
                <li>
                  <h3 className="h5">• Resistência muscular.</h3>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 justify-content-center d-flex align-items-center">
              <div>
                <h2 className="h4 h3-md text-center text-lg-left font-weight-bold mt-4 mt-lg-0 mb-4">
                  TREINO FUNCIONAL
                  <br />
                  PERSONALIZADO
                </h2>
                <p className="h5 h6-md">
                  Através de avaliação criteriosa, nossa equipe fará <br />
                  uma programação com intensidade e especificidade, <br />
                  proporcionando uma evolução constante na vida <br />
                  diária de nossos pacientes e&nbsp;clientes.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={agachamento} alt="Homem Treinando Agachamento" />
            </div>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Treinamento;
