import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, BgGrayInvert } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import rpg from '~/assets/metodos/rpg.png';
import tratamento from '~/assets/metodos/mulher-rpg.png';

function Rpg() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – RPG</title>
          <meta
            name="description"
            content="Clínica Physic: Técnicas de fisioterapia RPG que proporcionam uma coluna saudável e postura correta."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  RPG
                </h1>
                <h2 className="h4 h2-md my-5 my-lg-0 text-white font-italic text-center h4 font-weight-bold">
                  Reeducação Postural Global
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <h2 className="h4 h3-md font-weight-bold mt-4 mt-lg-0 mb-4 text-center text-lg-left">
                TÉCNICAS DE FISIOTERAPIA <br />
                RPG QUE PROPORCIONAM <br />
                UMA COLUNA SAUDÁVEL E <br />
                POSTURA CORRETA
              </h2>
              <img
                src={rpg}
                alt="Tratamento RPG"
                className="d-block d-lg-none mb-3"
              />
              <p className="h5 h6-md mb-3">
                Uma modalidade de tratamento conhecida pelos que sofrem de
                alguma alteração postural que acometem articulações, entre elas
                a coluna vertebral, fazendo com que ocorra desalinhamentos
                específicos da estrutura corporal como hipercifose, hiperlordose
                e escoliose.
              </p>
              <p className="h5 h6-md mb-3">
                Método para alongamento das cadeias musculares inteiras e
                reorganização dos segmentos do corpo.
              </p>
            </div>
            <div className="col-lg-7 d-none d-lg-block">
              <img src={rpg} alt="Tratamento RPG" />
            </div>
          </div>
        </div>
      </Container>
      <BgGrayInvert>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-4 py-lg-5 my-lg-5">
              <h2 className="h3 h1-md font-weight-bold mb-4">
                Para que serve:
              </h2>
              <ul>
                <li>
                  <h4 className="h6 h5-md">• Correção de desvios posturais;</h4>
                </li>

                <li>
                  <h4 className="h6 h5-md">• Problemas articulares;</h4>
                </li>

                <li>
                  <h4 className="h6 h5-md">• Dores nas costas;</h4>
                </li>

                <li>
                  <h4 className="h6 h5-md">• Melhora a respiração;</h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">
                    • Melhora o posicionamento da cabeça e os ombros;
                  </h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">
                    • Soluciona problemas de hérnia de disco;
                  </h4>
                </li>
                <li>
                  <h4 className="h6 h5-md">• Alivia a dor no ciático.</h4>
                </li>
              </ul>
              <p className="h5 h6-md">
                A Reeducação Postural Global (RPG) baseia-se nos princípios de
                que cada indivíduo reage de maneira diferente ao mesmo problema
                e de que não se deve tratar apenas o local da dor, mas todo o
                corpo como um todo.
              </p>
            </div>
          </div>
        </div>
        <img
          src={tratamento}
          alt="Tratamento RPG"
          className="d-block d-lg-none"
        />
      </BgGrayInvert>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Rpg;
