import styled from 'styled-components';

let background;
if (window.innerWidth >= 991) {
  background = require('~/assets/equipamentos/banner-equipamentos.png');
} else {
  background = require('~/assets/equipamentos/equipamentos-mobile.png');
}

export const Container = styled.div`
  h4 {
    color: #707070 !important;
  }
  .background {
    background-image: url(${background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .borda-palavras {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-weight: bold;
  }

  .rec-dot_active {
    background-color: #707070 !important;
    box-shadow: none !important;
    opacity: 0.75 !important;
  }

  .rec-dot {
    background-color: #707070;
    box-shadow: none;
    opacity: 0.25;
    height: 13px;
    width: 13px;
  }
`;
