import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-especialidades.png';
import mobile from '~/assets/banners-paginas/banner-especialidades-mobile.png';

export const Container = styled.div`
  h2 {
    color: #333;
  }

  h3,
  h6,
  a {
    color: #841619;
  }

  a {
    :hover {
      text-decoration: none;
    }
  }
  .border-btn {
    border: 1px solid #841619;
    border-radius: 20px;
  }
  .bg-espec {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 100px;
  }

  @media screen and (min-width: 1400px) {
    .mg-top {
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 768px) {
    .bg-espec {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 250px;
    }
  }
`;
