import styled from 'styled-components';

export const Container = styled.div`
  img {
    border-radius: 10px;
  }

  h1,p,
  h2 {
    color: #333;
  }

  .sub-titulo{
    h2{
    font-size: 1.5rem;
    }
  }

  .icons{
    color:#333;
    :hover{
      color: #c32027;
    }
    }
  }

  .data {
    color: #707070;
  }

  .btn-ler {
    color: #c32027;
    border: solid 1px #c32027;
    border-radius: 20px;
    :hover {
      color: #c32027;
    }
  }

  .bg-red {
    background-color: #c32027;
    border-radius: 20px 20px 0 0;
  }

  .borda {
    border: solid 1px #707070;
    border-top: none;
    border-radius: 0 0 20px 20px;
  }
`;
