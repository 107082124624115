import styled from 'styled-components';

export const Container = styled.div`
  .bg-footer {
    background-color: #282829;
  }

  .icons {
    background-color: #fff;
    color: #282829;
    border-radius: 20px;
    padding: 3px;
  }
  h4,
  p,
  a {
    color: #fff !important;
  }

  hr {
    background-color: #e62a2a;
    border-top: solid 1px #e62a2a !important;
  }

  ul li {
    line-height: 2rem;
  }
`;
