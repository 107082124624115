import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { IoIosArrowUp } from 'react-icons/io';

import { parseISO, format } from 'date-fns';
import { Link } from 'react-router-dom';
import api from '~/services/api';

import Contato from '~/components/Contato';
import Categorias from '~/components/Categorias';
import { Container } from './styles';

function Blog() {
  const [blog, setBlog] = useState([]);
  const [categoria, setCategoria] = useState(0);
  
  useEffect(() => {
    const category = localStorage.getItem('@physic/category');
    if (category) {
      setCategoria(category);
    }
  }, []);
  useEffect(() => {
    if (categoria === 0) {
      api.get('/blog').then(response => {
        const data = response.data.map(materia => {
          const description = materia.content
            .replace(/<[^>]*>?/gm, '')
            .split('.');
          return {
            id: materia.id,
            title: materia.title,
            thumbnail: materia.thumbnail,
            created_at: format(parseISO(materia.created_at), 'dd/MM/yyyy'),
            content: `${description[0]}…`,
            slug: materia.slug,
          };
        });
        setBlog(data);
      });
    } else {
      api.get(`blog/categories/${categoria}`).then(response => {
        const data = response.data.map(materia => {
          const description = materia.content
            .replace(/<[^>]*>?/gm, '')
            .split('.');
          return {
            id: materia.id,
            title: materia.title,
            thumbnail: materia.thumbnail,
            created_at: format(parseISO(materia.created_at), 'dd/MM/yyyy'),
            content: `${description[0]}…`,
            slug: materia.slug,
          };
        });
        setBlog(data);
      });
    }
  }, [categoria]);

  const handleSelect = useCallback(category_id => {
    setCategoria(category_id);
  }, []);

  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Blog</title>
          <meta
            name="description"
            content="Clínica Physic – Blog sobre dicas para a saúde muscular, alívio de dores e como evitar lesões no dia a dia. Agende sua consulta!"
          />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center font-weight-bold font-italic mt-5 py-3 py-lg-0">
                BLOG
              </h1>
            </div>
          </div>
        </div>

        <Categorias onSelect={handleSelect} />


        <div className="container">
          <div className="row my-4">
            <div className="col-xl-12">
              <div className="row">
                {blog.map(materia => (
                  <div className="col-md-6 col-lg-4 mb-5" key={materia.id}>
                    <img src={materia.thumbnail} alt={materia.title} />
                    <p className="data">{materia.created_at}</p>
                    <h2 className="h5 text-uppercase">{materia.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: materia.content }} />
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog/${materia.slug}`}
                      className="btn btn-ler px-4"
                    >
                      Ler mais
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>


          {/* <div className="row my-4">
            <div className="col-xl-12">
              <div className="row">
              {categorias.map(categoria => (
                    <button
                      key={categoria.id}
                      type="button"
                      // onClick={() => onSelect(categoria.id)}
                      onClick={() => onSelect(5)}
                      className="small-lg normal-xxl btn btn-categorias py-1 px-3 mr-2"
                    >
                      {categoria.name}
                    </button>
                  ))}
              </div>
            </div>
          </div> */}


          <div className="row">
            <div className="col">
              <div className="d-flex text-center justify-content-end">
                <AnchorLink
                  href="#categoria"
                  offset={130}
                  className="voltar mb-4"
                >
                  <IoIosArrowUp size={40} className="arrow" />
                  <br />
                  <span className="voltar h5">voltar ao topo</span>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contato />
    </>
  );
}

export default Blog;
