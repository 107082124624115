import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, BgGray } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import pronada from '~/assets/metodos/pronada.png';
import supinada from '~/assets/metodos/supinada.png';
import neutra from '~/assets/metodos/neutra.png';
import pe from '~/assets/metodos/barometria.png';

function Baropodometria() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Baropodometria</title>
          <meta
            name="description"
            content="A Clínica Physic realiza a Baropodometria, também conhecida como teste da pisada. Saiba se o seu tipo é: pronada, neutra ou supinada."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  BAROPODOMETRIA:
                </h1>
                <h2 className="h5 h4-md my-5 my-lg-0 text-white font-italic text-center font-weight-bold">
                  Teste da pisada
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center my-4">
              <h2 className="h4 h2-md font-weight-bold">
                SABER O SEU TIPO DE PISADA AJUDA A EVITAR LESÕES NOS JOELHOS,
                TORNOZELOS E COLUNA
              </h2>
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <img src={pe} alt="Analise pisada" className="d-block d-lg-none" />
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-end">
            <div className="col col-sm-9 col-lg-5 col-xxl-4 py-4 py-lg-5 my-lg-4">
              <p className="h5 h6-lg mb-3">
                A baropodometria é um exame que identifica as alterações
                biomecânicas nos pés nas posições ortostática, estática e
                dinâmica, por meio da análise da distribuição de pressão plantar
                e do deslocamento do centro de gravidade.
              </p>
              <p className="h5 red font-weight-bold">
                A captação dos dados é feita por uma plataforma composta por
                sensores que detectam:
              </p>
              <ul className="pl-lg-5">
                <li>
                  <h4 className="h6">
                    • Postura da pessoa em pé (posição ortostática);
                  </h4>
                </li>
                <li>
                  <h4 className="h6">• Equilíbrio postural;</h4>
                </li>
                <li>
                  <h4 className="h6">• Compensações posturais;</h4>
                </li>
                <li>
                  <h4 className="h6">
                    • Distribuição do peso corporal e dos picos de pressão nos
                    pés;
                  </h4>
                </li>
                <li>
                  <h4 className="h6">• Tipo de pé (normal, cavo ou neutro);</h4>
                </li>
                <li>
                  <h4 className="h6">• Tipo de pisada;</h4>
                </li>
                <li>
                  <h4 className="h6">• Alterações na marcha;</h4>
                </li>
                <li>
                  <h4 className="h6">
                    • Alterações nos pés, joelhos (valgo ou varo), quadril e
                    coluna;
                  </h4>
                </li>
                <li>
                  <h4 className="h6">
                    • Problemas que podem ser corrigidos por meio da utilização
                    das palmilhas posturais.
                  </h4>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 pr-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
          </div>
        </div>
      </BgGray>
      <Container>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center my-4">
              <h2 className="h4 h2-md font-weight-bold">
                CONHEÇA OS TIPOS DE PISADA:
              </h2>
            </div>
          </div>
          <div className="row d-none d-lg-flex justify-content-between my-5">
            <div className="col-lg-3">
              <div className="">
                <img src={pronada} alt="Pisada Pronada" />
                <h3 className="my-4 h2">Pisada pronada:</h3>
                <p>É a pisada para dentro.</p>
                <p>
                  A parte de fora do calcanhar toca no chão, o pé inicia uma
                  rotação excessiva para dentro.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="">
                <img src={supinada} alt="Pisada Supinada" />
                <h3 className="my-4 h2">Pisada Supinada:</h3>
                <p>É a pisada para fora.</p>
                <p>
                  O pé toca o solo com a face externa do calcanhar e se mantém
                  na banda externa do pé.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="">
                <img src={neutra} alt="Pisada Neutra" />
                <h3 className="my-4 h2">Pisada Neutra:</h3>
                <p>É a pisada normal.</p>
                <p>
                  A parte externa do calcanhar e o pé rotacionam ligeiramente
                  para dentro.
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-4 d-block d-lg-none">
            <Carousel showArrows={false}>
              <div className="col text-center">
                <div className="">
                  <img src={pronada} alt="Pisada Pronada" className="w-75" />
                  <h2 className="h3 my-4">Pisada pronada:</h2>
                  <p className="h5">É a pisada para dentro.</p>
                  <p className="h5">
                    A parte de fora do calcanhar toca no chão, o pé inicia uma
                    rotação excessiva para dentro.
                  </p>
                </div>
              </div>
              <div className="col text-center">
                <div className="">
                  <img src={supinada} alt="Pisada Supinada" className="w-75" />
                  <h2 className="h3 my-4">Pisada Supinada:</h2>
                  <p className="h5">É a pisada para fora.</p>
                  <p className="h5">
                    O pé toca o solo com a face externa do calcanhar e se mantém
                    na banda externa do pé.
                  </p>
                </div>
              </div>
              <div className="col text-center">
                <div className="">
                  <img src={neutra} alt="Pisada Neutra" className="w-75" />
                  <h2 className="h3 my-4">Pisada Neutra:</h2>
                  <p className="h5">É a pisada normal.</p>
                  <p className="h5">
                    A parte externa do calcanhar e o pé rotacionam ligeiramente
                    para&nbsp;dentro.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </Container>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Baropodometria;
