import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import { Container, BgGray, BgGrayInvert, Massagem } from './styles';

import voltar from '~/assets/icones/icone-voltar.svg';
import esportiva from '~/assets/metodos/esportiva.png';
import linfatica from '~/assets/metodos/linfatica.png';
import massagem from '~/assets/metodos/massagem.png';

function Massoterapia() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Clínica Physic – Massoterapia</title>
          <meta
            name="description"
            content="Clínica Physic atua com técnicas de massoterapia para promover bem-estar físico e mental para seus pacientes."
          />
        </Helmet>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="h2 h1-md text-white text-center font-weight-bold mg-top">
                  MASSOTERAPIA
                </h1>
                <h2 className="h5 h4-md my-5 my-lg-0 text-white font-italic text-center font-weight-bold">
                  Técnicas para o bem-estar físico, alívio de{' '}
                  <br className="d-none d-lg-block" />
                  tensões musculares e&nbsp;dores
                </h2>
                <div className="text-center mt-lg-4">
                  <Link
                    to={`${process.env.PUBLIC_URL}/especialidades`}
                    className="text-white h4"
                  >
                    <img src={voltar} alt="Icone voltar" className="mr-2" />
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center my-3 my-lg-4">
              <h2 className="h4 h2-md font-weight-bold">
                CONHEÇA NOSSAS MODALIDADES:
              </h2>
            </div>
            <div className="col-lg-8 text-center">
              <h3 className="h3 h1-md display-lg-4 font-weight-bold mb-4">
                Massagem esportiva
                <br />
                <span>(LIBERAÇÃO MIOFASCIAL)</span>
              </h3>
            </div>
          </div>
        </div>
      </Container>
      <BgGray>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-end">
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-5 py-lg-5 my-lg-5">
              <h2 className="h4 h1-md font-weight-bold mb-4">
                PARA QUE SERVE:
              </h2>
              <ul className="pl-lg-5">
                <li className="h5">• Ajuda a prevenir lesões;</li>
                <li className="h5">
                  • Aumenta a mobilidade articular e a consciência corporal;
                </li>
                <li className="h5">
                  • Diminui a sobrecarga e a tensão músculo-articular e as
                  tensões musculares;
                </li>
                <li className="h5">• Melhora a circulação sanguínea;</li>
                <li className="h5">• Ajuda a relaxar a musculatura;</li>
                <li className="h5">
                  • Auxilia na liberação do ácido lático, na recuperação
                  muscular e evita dores tardias;
                </li>
              </ul>
            </div>
            <div className="col-lg-6 pr-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={esportiva}
          alt="Massagem esportiva"
          className="d-block d-lg-none"
        />
      </BgGray>
      <Container>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h3 className="h3 h1-md display-lg-4 font-weight-bold my-4">
                Drenagem
                <br />
                <span>LINFÁTICA</span>
              </h3>
            </div>
          </div>
        </div>
      </Container>
      <BgGrayInvert>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-start">
            <div className="col-lg-6 pl-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-5 py-lg-5 my-lg-5">
              <h2 className="h4 h1-md font-weight-bold mb-4">
                PARA QUE SERVE:
              </h2>
              <ul className="pl-lg-5">
                <li className="h5">
                  • Ajuda na recuperação de lesões musculares e articulares;
                </li>
                <li className="h5">
                  • Reduz hematomas e melhora a circulação sanguínea;
                </li>
                <li className="h5">
                  • Combate o inchaço, a retenção de líquidos e a celulite;
                </li>
                <li className="h5">• Colabora na cicatrização dos tecidos;</li>
                <li className="h5">
                  • Ajuda a oxigenar os tecidos de forma mais adequada.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <img
          src={linfatica}
          alt="Massagem esportiva"
          className="d-block d-lg-none"
        />
      </BgGrayInvert>
      <Container>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h3 className="h3 h1-md display-lg-4 font-weight-bold my-4">
                Massagem
                <br />
                <span>RELAXANTE</span>
              </h3>
            </div>
          </div>
        </div>
      </Container>
      <Massagem>
        <div className="container-fluid">
          <div className="row justify-content-center justify-content-lg-end">
            <div className="col col-sm-9 col-lg-4 col-xxl-4 py-5 my-5">
              <h2 className="h4 h1-md font-weight-bold mb-4">
                PARA QUE SERVE:
              </h2>
              <ul className="pl-lg-5">
                <li>
                  <h4 className="h5">• Alivia tensões;</h4>
                </li>
                <li>
                  <h4 className="h5">
                    • Traz um efeito de bem-estar e tranquilidade;
                  </h4>
                </li>
                <li>
                  <h4 className="h5">• Reduz a ansiedade;</h4>
                </li>
                <li>
                  <h4 className="h5">• Acalma a mente;</h4>
                </li>
                <li>
                  <h4 className="h5">• Alivia a fadiga muscular.</h4>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 pr-lg-0">
              <div className="poligonal h-100 w-100">
                <div className="h-100 w-100" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={massagem}
          alt="Massagem esportiva"
          className="d-block d-lg-none"
        />
      </Massagem>
      <Equipamentos />
      <Contato />
    </>
  );
}

export default Massoterapia;
