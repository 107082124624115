import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Carousel from 'react-elastic-carousel';
import Slider from 'react-slick';

import Equipamentos from '~/components/Equipamentos';
import Contato from '~/components/Contato';

import ortopedia from '~/assets/metodos/ortopedia.svg';
import fisioterapia from '~/assets/metodos/fisioterapia.svg';
import pilates from '~/assets/metodos/pilates.svg';
import exercises from '~/assets/metodos/exercise.svg';
import medicina from '~/assets/metodos/medicina.webp';
import profissionais from '~/assets/metodos/profissionais.webp';
import funcional from '~/assets/metodos/funcional.webp';
import h5 from '~/assets/metodos/h5.webp';

import { Container } from './styles';

function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <Container className="text-center text-lg-left">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Clínica Physic – Medicina, Fisioterapia e Treinamento Funcional
          </title>
          <meta
            name="description"
            content="A Clínica Physic é especialista em ortopedia, fisioterapia, treinamento funcional e mais! Localizada no bairro Ibirapuera."
          />

          <meta name="keywords"
            content="clinica de fisioterapia em são paulo, baropodometria em são paulo, ortopedia em são paulo, clinica physic, fisioterapia, acupuntura em sp, osteopatia em sp, ergonomia em sp, rpg em sp, assessoria de corrida em sp, massoterapia em sp, pilates em sp, treinamento funcional em sp, teste de biomecânica em sp" />

        </Helmet>
        <Slider {...settings}>
          <div className="banner1">
            <div className="text-center mg-title">
              <h2 className="text-white font-weight-bold h1">MEDICINA,</h2>
              <p className="text-white h4 mb-4">
                fisioterapia e tratamento <br className="d-sm-none d-block" />
                funcional
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades`}
                className="text-white btn-banner"
              >
                Saiba mais
              </Link>
            </div>
          </div>
          <div className="banner2">
            <div className="text-center mg-title mg-h5">
              <h2 className="text-white font-weight-bold h1">H5 HEALTH</h2>
              <p className="text-white h4 mb-4">
                Método exclusivo para desenvolvimento de alta
                <br /> performance, bem-estar e emagrecimento
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/h5-health`}
                className="text-white btn-banner"
              >
                Saiba mais
              </Link>
            </div>
          </div>
          <div className="banner3">
            <div className="text-center mg-title">
              <h2 className="text-white font-weight-bold h1">PILATES</h2>
              <p className="text-white h4 mb-4">
                Força, resistência e flexibilidade
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/especialidades/pilates`}
                className="text-white btn-banner"
              >
                Saiba mais
              </Link>
            </div>
          </div>
          <div className="banner4">
            <div className="text-center mg-title">
              <h2 className="text-white font-weight-bold h1">EQUIPAMENTOS</h2>
              <p className="text-white h4 mb-4">
                de última geração para o seu tratamento
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/consultorios`}
                className="text-white btn-banner"
              >
                Saiba mais
              </Link>
            </div>
          </div>
        </Slider>
        <div className="bg-red d-none d-lg-block">
          <div className="container py-4">
            <div className="row">
              <div className="col-lg-3 text-center">
                <img src={ortopedia} alt="ortopedia" />
                <h1 className="h6 text-white mb-0 mt-1">Ortopedia</h1>
              </div>
              <div className="col-lg-3 text-center">
                <img src={fisioterapia} alt="fisioterapia" />
                <h2 className="h6 text-white mb-0 mt-2">Fisioterapia</h2>
              </div>
              <div className="col-lg-3 text-center">
                <img src={pilates} alt="pilates" />
                <h2 className="h6 text-white mb-0 mt-3">Pilates</h2>
              </div>
              <div className="col-lg-3 text-center">
                <img
                  src={exercises}
                  alt="Treinamento Funcional"
                  className="mt-1"
                />
                <h2 className="h6 text-white mb-0 mt-2">
                  Treinamento Funcional
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-red d-lg-none d-block ">
          <div className="container py-4">
            <Carousel className="align-items-center" showArrows={false}>
              <div className="col-12 mt-2 d-flex">
                <div className="col-6 text-center d-contents">
                  <img src={ortopedia} alt="ortopedia" />
                  <h1 className="h6 text-white mb-0 mt-1">Ortopedia</h1>
                </div>
                <div className="col-6 text-center d-contents">
                  <img src={fisioterapia} alt="fisioterapia" />
                  <h2 className="h6 text-white mb-0 mt-2">Fisioterapia</h2>
                </div>
              </div>
              <div className="col-12 mt-2 d-flex">
                <div className="col-6 text-center d-contents">
                  <img src={pilates} alt="pilates" />
                  <h2 className="h6 text-white mb-0 mt-3">Pilates</h2>
                </div>
                <div className="col-6 text-center d-contents">
                  <img
                    src={exercises}
                    alt="Treinamento Funcional"
                    className="mt-1"
                  />
                  <h2 className="h6 text-white mb-0 mt-2">
                    Treinamento Funcional
                  </h2>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
        <div className="bg-light-gray">
          <div className="container pb-4 pb-lg-0">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center pt-4">
                <p className="h3-lg h4 color font-weight-bold">
                  Cuidados personalizados e completos&nbsp;para&nbsp;mais <br />
                  <span
                    data-texto="QUALIDADE, BEM-ESTAR E SEGURANÇA NOS SEUS MOVIMENTOS."
                    className="color-qualidade font-weight-bold"
                  >
                    <em>QUALIDADE, BEM-ESTAR E SEGURANÇA</em>{' '}
                  </span>
                  <span className="font-weight-bold">
                    <i>NOS SEUS MOVIMENTOS.</i>
                  </span>
                </p>
              </div>
              <div className="col-lg-7 pt-3">
                <img src={medicina} alt="Medicina Fisioterapia" />
              </div>
              <div className="col-lg-5 pt-3 text-left">
                <h3 className="h3-lg h2-xl color-medicina font-weight-bold">
                  Medicina e <br />
                  <span className="letras-borda h1 display-xl-4">
                    FISIOTERAPIA
                  </span>
                </h3>
                <p className="h5 h6-lg h5-xl mt-3">
                  Um corpo clínico completo para prevenção e tratamento de
                  lesões e traumas ortopédicos:
                </p>
                <ul className="ml-3 my-4 ml-lg-5 my-lg-5">
                  <li>
                    <h4 className="h6">• Recuperação e prevenção de lesões;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Tratamento individual;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Reabilitação.</h4>
                  </li>
                </ul>
                <Link
                  to={`${process.env.PUBLIC_URL}/especialidades/fisioterapia-ortopedica`}
                  className="px-3 py-2 btn-veja"
                >
                  Saiba mais
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 text-left py-4 py-lg-0 order-1 order-lg-0">
                <h3 className="h3-lg h2-xl color-medicina font-weight-bold">
                  Atletas profissionais <br />
                  <span className="letras-borda h1 display-xl-4">
                    E AMADORES
                  </span>
                </h3>
                <p className="h5 h6-lg h5-xl mt-3">
                  Treinamentos para melhorar o desempenho e atingir a melhor
                  performance de atletas, com programas personalizados:
                </p>
                <ul className="ml-3 ml-lg-5 my-4 my-lg-2 my-xl-4">
                  <li>
                    <h4 className="h6">
                      <b>• Runner:</b>
                    </h4>
                  </li>
                  <li>
                    Mais potência, agilidade. Flexibilidade, mobilidade e
                    resistência.
                  </li>
                  <li className="mt-3">
                    <h4 className="h6">
                      <b>• Recovery:</b>
                    </h4>
                  </li>
                  <li>Restauração da função do músculo e microtrauma.</li>
                </ul>
                <Link
                  to={`${process.env.PUBLIC_URL}/atletas`}
                  className="px-3 py-2 btn-veja"
                >
                  Saiba mais
                </Link>
              </div>
              <div className="col-lg-7 order-0 order-lg-1">
                <img src={profissionais} alt="Homem se alongando " />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light-gray">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <img src={funcional} alt="Homem treinando" />
              </div>
              <div className="col-lg-5 py-4 py-lg-0 text-left">
                <h3 className="h3-lg h2-xl color-medicina font-weight-bold">
                  Treinamento funcional <br />
                  <span className="letras-borda h1 display-xl-4">
                    PERSONALIZADO
                  </span>
                </h3>
                <p className="h5 h6-lg h5-xl mt-3">
                  Programa Physic Fit resulta em melhor desempenho para sua
                  funcionalidade <br className="d-none d-lg-block" />
                  no dia a dia.
                </p>
                <ul className="ml-3 ml-lg-5 my-4">
                  <li>
                    <h4 className="h6">• Profissionais especializados;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Orientação personalizada;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Aumento de resistência; </h4>
                  </li>
                  <li>
                    <h4 className="h6">• Avaliação criteriosa. </h4>
                  </li>
                </ul>
                <Link
                  to={`${process.env.PUBLIC_URL}/especialidades/treinamento-funcional`}
                  className="px-3 py-2 btn-veja"
                >
                  Saiba mais
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 text-left py-4 py-lg-0 order-1 order-lg-0">
                <h3 className="h3-lg h2-xl color-medicina font-weight-bold">
                  Método <br />
                  <span className="letras-borda h1 display-xl-4">
                    H5 HEALTH
                  </span>
                </h3>
                <p className="h6-lg h5-xl h5 mt-3">
                  Método exclusivo para trabalhar a alta performance com a união
                  de 5 especialidades:
                </p>
                <ul className="ml-3 ml-lg-5 my-4">
                  <li>
                    <h4 className="h6">• Medicina;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Nutrição;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Fisioterapia;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Educação Física;</h4>
                  </li>
                  <li>
                    <h4 className="h6">• Psicologia.</h4>
                  </li>
                </ul>
                <Link
                  to={`${process.env.PUBLIC_URL}/h5-health`}
                  className="px-3 py-2 btn-veja"
                >
                  Saiba mais
                </Link>
              </div>
              <div className="col-lg-7 order-0 order-lg-1">
                <img src={h5} alt="Medindo tamanho da barriga" />
              </div>
            </div>
          </div>
        </div>
        <Equipamentos />
        <Contato />
      </Container>
    </>
  );
}

export default Home;
