import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-osteopatia.png';
import mobile from '~/assets/banners-paginas/banner-osteopatia-mobile.png';
import bgGrayOsteopatia from '~/assets/metodos/osteopatia-bgray.png';

export const Container = styled.div`
  p,
  ul li {
    color: #333;
  }

  ul li {
    line-height: 2rem;
  }

  h2,
  h3 {
    color: #841619;
  }
  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 210px;
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 320px;
    }
  }

  @media screen and (max-width: 575px) {
    .mg-top {
      margin-top: 370px;
    }
  }
`;

export const BgGrayInvert = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  h3,
  ul li {
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    color: #333;
  }

  h2 {
    color: #841619;
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(88% 100%, 0% 100%, 0% 0%, 98% 0%);
    background: #fff;
    div {
      background-image: url(${bgGrayOsteopatia});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(86% 100%, 0% 100%, 0% 0%, 96% 0%);
    }
  }
`;
