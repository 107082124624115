import React, { useState } from 'react';
import { Form, Input, Select } from '@rocketseat/unform';
import { FaSpinner } from 'react-icons/fa';
import * as Yup from 'yup';
import swal from 'sweetalert2';
import CelMask from '~/components/CelMask';

import api from '~/services/api';

import { Container } from './styles';

const schema = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório'),
  email: Yup.string().required('O e-mail é obrigatório'),
  telefone: Yup.string().required('O telefone é obrigatório'),
  especialidade: Yup.string().required('A especialidade é obrigatória'),
});

function Contato() {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(
    { nome, email, telefone, especialidade },
    { resetForm }
  ) {
    setLoading(true);

    const formData = new URLSearchParams();
    formData.append('nome', nome);
    formData.append('email', email);
    formData.append('telefone', telefone);
    formData.append('especialidade', especialidade);

    const response = await api.post('contato', formData);

    if (response.data) {
      swal
        .fire(
          'Obrigado pelo seu contato!',
          'Logo entraremos em contato!',
          'success'
        )
        .then(_ => {
          resetForm();
        });
    } else {
      swal.fire(
        'Ocorreu um erro',
        'Desculpe o incômodo, mas um erro inesperado aconteceu tente novamente;',
        'error'
      );
    }
    setLoading(false);
  }

  const options = [
    {
      id: 'Acupuntura',
      title: 'Acupuntura',
    },

    {
      id: 'Baropodometria',
      title: 'Baropodometria',
    },

    {
      id: 'Ergonomia',
      title: 'Ergonomia',
    },

    {
      id: 'Fisioterapia',
      title: 'Fisioterapia',
    },

    {
      id: 'Massoterapia',
      title: 'Massoterapia',
    },

    {
      id: 'Ortopedia',
      title: 'Ortopedia',
    },

    {
      id: 'Osteopatia',
      title: 'Osteopatia',
    },

    {
      id: 'Pilates',
      title: 'Pilates',
    },

    {
      id: 'RPG',
      title: 'RPG',
    },

    {
      id: 'Teste de Biomecânica',
      title: 'Teste de Biomecânica',
    },

    {
      id: 'Treinamento Funcional',
      title: 'Treinamento Funcional',
    },
  ];

  return (
    <Container>
      <div className="bg-gradiente">
        <div className="container">
          <div className="row py-4">
            <div className="col-lg-12 text-center py-4 py-lg-0">
              <h2
                className="text-white h4 font-weight-bold display-lg-4 mb-0"
                id="contato"
              >
                MOVIMENTE-SE COM MAIS <br />
                <span className="borda-palavras h2 display-lg-4">
                  SAÚDE E CUIDADO!
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <Form schema={schema} onSubmit={handleSubmit}>
          <div className="row justify-content-center text-left px-3 px-lg-0">
            <div className="col-md-8 col-xl-6 form-group">
              <label htmlFor="nome">Nome Completo</label>
              <Input name="nome" className="form-control" id="nome" />
            </div>
            <div className="col-md-4 col-xl-3 form-group mask-celular">
              <label htmlFor="celular">Celular</label>
              <CelMask name="telefone" id="cel" className="form-control" />
            </div>
            <div className="col-md-8 col-xl-6 form-group mask-cep">
              <label htmlFor="email">E-mail</label>
              <Input
                name="email"
                type="email"
                className="form-control"
                id="email"
              />
            </div>
            <div className="col-md-4 col-xl-3 col-12 form-group">
              <label htmlFor="especialidade">Especialidade desejada</label>
              <Select
                name="especialidade"
                className="form-control"
                id="especialidade"
                options={options}
                placeholder="selecione"
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9 d-flex mt-3 justify-content-center justify-content-lg-end">
              <button
                type="submit"
                className="btn btn-enviar"
                disable={loading}
                onClick={() => window.gtag_report_conversion_lead()}
              >
                {loading ? (
                  <FaSpinner size={20} color="#43b0b0" className="spin" />
                ) : (
                  'ENVIAR'
                )}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default Contato;
