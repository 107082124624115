import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { FaWhatsapp, FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Container } from './styles';

import GDPRPanel from '~/components/GDPRPanel';

import logoFooter from '~/assets/logo-footer/logo-footer.png';

function Footer() {
  const year = useMemo(() => {
    const date = new Date();
    return date.getFullYear();
  }, []);
  return (
    <>
      <Container className="text-center text-lg-left">
        <div className="bg-footer pb-5 pt-5 mt-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12 text-left">
                    <h4 className="h5 h6-lg">Atendimento</h4>
                    <p className="h5 h6-lg">
                      Segunda a sexta-feira, das <b>7h às 21h</b>
                      <br />E aos sábados das <b>08h às 13h</b> (consultar
                      especialidades atendidas)
                    </p>
                    <h4 className="h5 h6-lg d-lg-none d-block mt-4 mt-lg-0">
                      Endereço
                    </h4>
                    <p className="h5 h6-lg d-lg-none d-block">
                      Av. República do Líbano, 592
                      <br />
                      Ibirapuera, São Paulo
                    </p>
                  </div>
                  <div className="col-lg-6 text-left">
                    <h4 className="mb-0 mt-4 mt-lg-0">Principal</h4>
                    <hr className="my-2 w-50" />
                    <ul>
                      <li>
                        <Link to="/" className="h5 h6-lg">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/especialidades`}
                          className="h5 h6-lg"
                        >
                          Especialidades
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/h5-health`}
                          className="h5 h6-lg"
                        >
                          H5
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/atletas`}
                          className="h5 h6-lg"
                        >
                          Atletas
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/consultorios`}
                          className="h5 h6-lg"
                        >
                          Consultórios
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/quem-somos`}
                          className="h5 h6-lg"
                        >
                          Quem Somos
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 text-left">
                    <h4 className="mt-4 mt-lg-0">Entretenimento</h4>
                    <hr className="my-2 w-50" />
                    <ul>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/blog`}
                          className="h5 h6-lg"
                        >
                          Blog
                        </Link>
                      </li>
                    </ul>
                    <h4 className="mt-4 mt-lg-0">Entre em contato</h4>
                    <hr className="my-2 w-50" />
                    <ul>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/contato`}
                          className="h5 h6-lg"
                        >
                          Contato
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 text-lg-right">
                <h4 className="h6 d-none d-lg-block mt-4 mt-lg-0">Endereço</h4>
                <p className="d-none d-lg-block">
                  Av. República do Líbano, 592
                  <br />
                  Ibirapuera, São Paulo
                </p>
                <img
                  src={logoFooter}
                  alt="Logo Physic"
                  className="w-75 w-lg-50"
                />
                <div className="col-12 px-0 mt-4">
                  <a
                    href="https://api.whatsapp.com/send?phone=551135966650"
                    target="blank"
                    onClick={() => window.gtag_report_conversion_whats()}
                  >
                    <FaWhatsapp size={40} className="icons mx-2" />
                  </a>
                  <a href="https://www.facebook.com/ClinicaPhysic/">
                    <FaFacebookF size={40} className="icons mx-2" />
                  </a>
                  <a href="https://www.instagram.com/clinicaphysic/">
                    <FaInstagram size={40} className="icons mx-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <p className="my-0 my-lg-2 pb-2 text-center">
              {year} Copyright - Todos os Direitos Reservados Clínica Physic
            </p>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-lg-end">
            <p className="text-center my-0 my-lg-2 pb-2">
              Criado por{' '}
              <a href="https://amzmp.com.br" target="blank">
                amz|mp
              </a>
            </p>
          </div>
        </div>
      </div>
      <GDPRPanel />
    </>
  );
}

export default Footer;
