import styled from 'styled-components';

export const Container = styled.div`
  .btn-categorias {
    color: #707070;
    border-radius: 20px;
    border: solid 1px #707070;
    :hover {
      color: #fff;
      background: #c32027;
      border: solid 1px #c32027;
    }
  }

  @media screen and (min-width: 992px) {
    .btn {
      :focus {
        color: #fff;
        background: #c32027;
        border: solid 1px #c32027;
        box-shadow: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .btn.focus,
    .btn:focus {
      box-shadow: none;
    }
    .dropdown-menu.show {
      width: 206px;
    }
    .btn-categorias-mob {
      border: none;
      border-radius: 0px;
      line-height: 2rem;
      :hover {
        color: #707070;
        background: transparent;
        border: none;
      }
    }
    .btn-mais {
      color: #707070;
      border-radius: 20px;
      border: solid 1px #707070;
      background-color: transparent;
      :hover {
        color: #fff;
        background: #c32027;
        border: solid 1px #c32027;
      }
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      background-color: #c32027 !important;
      border: solid 1px #c32027 !important;
    }
    .btn-primary.focus,
    .btn-primary:focus {
      background-color: transparent !important;
      border: solid 1px #707070 !important;
      color: #707070;
    }

    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: none !important;
    }
  }
`;
