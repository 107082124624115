import styled from 'styled-components';

import banner from '~/assets/banners-paginas/banner-corrida.jpg';
import mobile from '~/assets/banners-paginas/banner-corrida-mobile.jpg';
//import tratamento from '~/assets/metodos/mulher-rpg.png';
import corrida from '~/assets/metodos/corrida.png';
import esportiva from '~/assets/metodos/esportiva.png';
import denis from '~/assets/metodos/denis-2.png';

export const Container = styled.div`
  p,
  ul li {
    color: #333;
  }

  ul li {
    line-height: 2rem;
  }

  h2,
  h3 {
    color: #841619;
  }
  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 170px;
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 240px;
    }
  }

  @media screen and (max-width: 575px) {
    .mg-top {
      margin-top: 300px;
    }
  }
`;

export const BgGrayInvert = styled.div`
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  ul li {
    color: #333;
  }

  p {
    color: #333;
  }

  h2,
  h3 {
    color: #841619;
  }

  .txt {
    font-size: 24px;
  }

  .padding-left {
    padding-left: 20%;
  }

  .poligonal {
    clip-path: polygon(88% 100%, 0% 100%, 0% 0%, 98% 0%);
    background: #fff;
    div {
      background-image: url(${corrida});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(86% 100%, 0% 100%, 0% 0%, 96% 0%);
    }
  }

  @media screen and (max-width: 768px) {
    h4,
    ul li {
      margin-bottom: 0.5rem;
    }
  }
`;

export const BgGray = styled.div`
  
  background-color: #e7e7e8;
  z-index: 0;
  position: relative;

  ul li {
    color: #333;
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    color: #841619;
  }

  .padding-left {
    padding-left: 20%;
  }

  .txt {
    font-size: 24px;
  }

  .txt-2 {
    font-size: 21px;
    color: #333333;
    font-weight: 500 !important;
  }

  .poligonal {
    clip-path: polygon(100% 100%, 20% 100%, 0% 0%, 100% 0%);
    background: #fff;
    div {
      background-image: url(${denis});
      background-position: top center;
      background-size: cover;
      clip-path: polygon(100% 100%, 22% 100%, 2% 0%, 100% 0%);
    }
  }

  @media screen and (max-width: 991px) {
    ul li {
      margin-bottom: 0;
    }
  }
`;