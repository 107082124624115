import styled from 'styled-components';
import banner from '~/assets/banners-paginas/banner-qsomos.png';
import mobile from '~/assets/banners-paginas/banner-somos-mobile.png';

export const Container = styled.div`
  h2 {
    color: #841619;
  }

  h3,
  p {
    color: #333;
  }

  .banner {
    background-image: url(${banner});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 25% 0;
    height: 650px;
    width: 100%;
    margin-top: 31px;
  }

  .mg-top {
    margin-top: 150px;
  }

  .titulo {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  .bold {
    font-weight: bold;
    font-style: italic;
  }

  @media screen and (min-width: 1400px) {
    .mg-top {
      margin-top: 250px;
    }
  }

  @media screen and (max-width: 768px) {
    .banner {
      background-image: url(${mobile});
      background-position: center right;
    }

    .mg-top {
      margin-top: 250px;
    }

    .normal {
      font-weight: normal;
      font-style: normal;
    }
  }
`;

export const BgGray = styled.div`
  background-color: #e7e7e8;

  h2,
  h3 {
    color: #841619;
  }

  ul li,
  p {
    color: #333;
    margin-bottom: 0.9rem;
    font-size: 0.9rem;
  }

  ul li {
    margin-bottom: 1rem;
  }

  .titulo {
    span {
      color: transparent;
      -webkit-text-stroke: 1.5px #333;
    }
  }

  @media screen and (max-width: 1400px) {
    ul li,
    p {
      margin-bottom: 0.6rem;
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 991px) {
    ul li,
    p {
      color: #333;
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }
`;

export const Equipe = styled.div`
  h2,
  h3 {
    color: #841619;
  }

  ul li,
  p {
    color: #333;
    margin-bottom: 0.6rem;
    font-size: 0.8rem;
  }
  .sombra {
    color: #841619;
    span::after {
      color: transparent;
      -webkit-text-stroke: 1px rgba(167, 171, 173, 0.52);
      display: block;
      content: attr(data-texto);
      z-index: -1;
      position: absolute;
      bottom: 4px;
      left: 442px;
    }
  }

  .red {
    color: #841619;
  }

  @media screen and (min-width: 1400px) {
    ul li,
    p {
      color: #333;
      margin-bottom: 0.9rem;
      font-size: 0.9rem;
    }

    .sombra {
      span::after {
        bottom: 4px;
        left: 532px;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .sombra {
      span::after {
        bottom: 4px;
        left: 353px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    ul li,
    p {
      margin-bottom: 1rem;
      font-size: 1rem;
    }
    .mg-borda {
      margin-right: 189px;
      margin-top: -20px;
    }

    .absolute-mobile {
      position: absolute;
    }

    .sombra {
      span::after {
        bottom: -3px;
        left: -3px;
      }
    }
  }
`;
